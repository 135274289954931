$(document).ready(function() {
	$(".navbar_outer .drop_box #notificationLists").on("click", ".unread-bg", function() {
		var activity_id = $(this).attr("activity_id");
		var payload = {
			activity_id: activity_id
		};
		$.ajax({
			url: "/notification/is_read",
			method: "POST",
			dataType: "json",
			data: payload,
			headers: {
				'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content')
			},
		})

	});

	$('#changeReadAll').on('click', function(){
    let url = "/notification/all_read"
    $.ajax({
      url: url,
      method: "POST",
      dataType: "json",
      headers: {
				'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content')
			},
		}).done(function (){
			location.reload()
		})
	})


	$('.change_read_unread').on("change", function(){
		let thisElement = $(this)
		let parentBox = thisElement.closest('.drop_box')
		if(thisElement.prop('checked')==true){
			parentBox.find('.unread-box').removeClass('d-none')
			parentBox.find('.read-box').addClass('d-none')

		}else{
			parentBox.find('.unread-box').addClass('d-none')
			parentBox.find('.read-box').removeClass('d-none')
		}
	})

	if ($('.notification-number').length > 0) {
		$('.notification-number').each(function(element) {
			var number = $(this).text();
			var numberSize = number.length
			if (parseInt(number) > 99) {
				$(this).text('99+')
			}
			if (numberSize == 2 || numberSize == 1) {
				$('.rippler').css('width', $(this).outerWidth(true) + 10 + numberSize + 'px');
			} else {
				$('.rippler').css('width', $(this).outerWidth(true) + 11 + 'px');
			}
		})
	}

	$("#navbarSearchIcon").on("click", function() {
		$(this).closest('div').find('.navbar-search').animate({'width': 'toggle'});
	});

	$('div.fm_item').on('click', function(){
		const link = $(this).data("link");
		if(!$(this).hasClass('create-ticket')) {
			$('.fm_item_outer').hide()
		}
		$(`#${link}`).slideDown();
	});

	$('.fm_title').on('click', function(){
		$('.fm_detail').slideUp('400', function(){
			$('.fm_item_outer').fadeIn()
		});
	});

	$('#crmMenu').on('click', function(){
    $(this).next('.nav-tabs').slideToggle({
      start: function(){
        jQuery(this).css('display', 'flex')
      }
    });
  })
	$(window).on('load resize', function(){
		if($(this).outerWidth() >= 1024){
			$('#crmMenu').next('.nav-tabs').css('display', 'flex')
		}else{
			$('#crmMenu').next('.nav-tabs').css('display', 'none')
		}
		changeHeightDropBox()
	});

	$('.drop_btn').on('click', function(){
		let dropName = $(this).data('name')
		let dropBox = $('#'+dropName)

		if(dropName == 'notificationDropbox')
			$('#newsDropbox').addClass('d-none')
		else if(dropName == 'newsDropbox')
			$('#notificationDropbox').addClass('d-none')

		// if(dropBox.hasClass('d-none')){
			dropBox.toggleClass('d-none')
			changeHeightDropBox(dropName)
		// }else{
			// dropBox.addClass('d-none')
		// }
	})
	$(document).on('mouseup', function(e) {
   	let container = $('.drop_box').not('.d-none')
		let dropBtn = $('.drop_btn').find('.icon')
		let number = $('.notification-number')
    if ((!container.is(e.target) && !dropBtn.is(e.target) && !number.is(e.target)) && container.has(e.target).length === 0 && !container.hasClass('d-none')) {
      $(container).addClass('d-none');
    }
	})

	function changeHeightDropBox(dropName){
		let element_box = $(`#${dropName}`)
		let box_height = element_box.outerHeight()
		let max_height = $(window).outerHeight() - 65
		if(max_height < box_height){
			element_box.css('height', max_height+'px')
		}else{
			element_box.css('height', 'auto')
		}
	}

	$(document).on('click', function(e) {
		if ($(e.target).parents('#sidebar-recently').length > 0) return
		if ($(e.target).parents('#recently-projects').length > 0) return
		if ($(e.target).parents('#recently-tasks').length > 0) return
		$('.external-link-size').addClass('d-none');
	})
})
